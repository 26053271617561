import React from 'react';
import { playgroundTypes } from 'Constant';
import { getCloudFlareImageUrl } from 'Utils';
import { useMediaQuery } from '../../../hooks';
import classes from './styles.module.css';

const OptionCard = ({ option, handleOptionSelection, isSelected }) => {
  const { name, image } = option;
  return (
    <div
      onClick={handleOptionSelection}
      className={`flex flex-col text-primary cursor-pointer ${classes.grass} ${
        isSelected ? classes.selected_grass : ''
      }`}
    >
      <img decoding='async' src={getCloudFlareImageUrl(image)} alt={name} />
      <h3 className='font-bold mt-3 mb-2 text-center font-Monteserrat'>{name}</h3>
    </div>
  );
};

const PlaygroundOptions = ({ step, values, setStep, setFieldValue }) => {
  const { isMobile } = useMediaQuery();

  return (
    <div
      style={{
        display: step === 3 ? 'block' : 'none',
        padding: isMobile ? '0 1rem' : '0 5rem',
      }}
    >
      <div className='text-center py-3'>
        <h3 className='text-[26px] font-bold text-secondary font-lawn'>
          What’s your current playground type
        </h3>
      </div>

      <div className='flex gap-4 mb-3 mt-4 flex-wrap justify-center'>
        {playgroundTypes.map((option) => (
          <OptionCard
            option={option}
            key={option.value}
            isSelected={values.playgroundType === option.value}
            handleOptionSelection={() => {
              setFieldValue('playgroundType', option.value);
              setStep(4);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default PlaygroundOptions;
