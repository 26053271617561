import React from 'react';
import { GRASS_TYPES_OPTIONS, INSTALLATION_OPTIONS, installationTypes } from 'Constant';
import { getCloudFlareImageUrl } from 'Utils';
import { useMediaQuery } from '../../../hooks';
import classes from './styles.module.css';

const OptionCard = ({ option, handleOptionSelection, isSelected }) => {
  const { name, image } = option;
  return (
    <div
      onClick={handleOptionSelection}
      className={`flex flex-col text-primary cursor-pointer ${classes.grass} ${
        isSelected ? classes.selected_grass : ''
      }`}
    >
      <img decoding='async' src={getCloudFlareImageUrl(image)} alt={name} />
      <h3 className='font-bold mt-3 mb-2 text-center font-Monteserrat'>{name}</h3>
    </div>
  );
};

const Options = ({ step, setStep, values, setFieldValue }) => {
  const { isMobile } = useMediaQuery();

  return (
    <div
      style={{
        display: step === 2 ? 'block' : 'none',
        padding: isMobile ? '0 1rem' : '0 5rem',
      }}
    >
      <div className='text-center py-3'>
        <h3 className='text-[26px] font-bold text-secondary font-lawn'>
          Select Your Installation Type
        </h3>
        <h4 className='text-darkGreen mt-5 text-center text-sm font-Monteserrat'>
          <span className='font-bold'>Don’t worry,</span> you can always change your mind later.
        </h4>
      </div>

      <div className='flex gap-2 mb-3 mt-2 flex-wrap justify-center'>
        {installationTypes.map((option) => (
          <OptionCard
            option={option}
            key={option.value}
            isSelected={values.installationOption === option.value}
            handleOptionSelection={() => {
              setFieldValue('installationOption', option.value);
              if (option.value === INSTALLATION_OPTIONS.PUTTING_GREEN) {
                setFieldValue('grass', GRASS_TYPES_OPTIONS.PUTTING_GREEN);
                setStep(5);
              } else if (option.value === INSTALLATION_OPTIONS.PLAYGROUND) {
                setStep(3);
              } else {
                setStep(4);
              }
              if (option.value !== INSTALLATION_OPTIONS.PLAYGROUND) {
                setFieldValue('playgroundType', null);
              }
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default Options;
