import * as Yup from 'yup';

export const contactValidationSchema = Yup.object().shape({
  fname: Yup.string().required('First Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  estimateArea: Yup.number().min(1, 'Area must be at least 1 sq/ft').required('Area is required'),
  lname: Yup.string(),
  phone: Yup.string(),
});

export const manualEstimateValidationSchema = Yup.object().shape({
  fname: Yup.string(),
  email: Yup.string().email('Please enter a valid email').required('Email is required'),
  estimateArea: Yup.number().min(1, 'Area must be at least 1 sq/ft').required('Area is required'),
  lname: Yup.string(),
  phone: Yup.string(),
  grass: Yup.string().required('Grass Type is required'),
  installationOption: Yup.string().required('Installation Type is required'),
});
