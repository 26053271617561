import React from 'react';
import './style.css';

/**
  @name Loader
  @description Displays a loader when loading estimate data from the server
  @param {string} message - Message to display
  @param {string} customClass - Custom class to add to the loader
  @returns {JSX} Loader
  */
export const Loader = ({ message, customClass }) => (
  <div className={`flex justify-center items-center ${customClass ?? ''}`}>
    <div className='flex flex-col items-center'>
      <div className='lds-dual-ring' />
      {message && <h4 className='text-primary my-3 font-bold '>{message}</h4>}
    </div>
  </div>
);

/**
 @name ButtonLoader
  @description Displays a loader for within a button
  @returns {JSX} Button Loader
  */
export const ButtonLoader = () => (
  <div className='flex justify-center items-center'>
    <div className='lds-dual-ring-button' />
  </div>
);
