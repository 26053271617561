/* eslint no-underscore-dangle: 0 */
/* eslint no-param-reassign: 0 */
/* eslint arrow-body-style: 0 */
/* eslint prefer-arrow-callback: 0 */
/* eslint import/no-cycle: 0 */

import axios from 'axios';
import { encryptLazySecuritySeal } from 'Utils';

export const baseUrl =
  process.env.NODE_ENV === 'development'
    ? 'http://127.0.0.1:8000/api/v1' // DEVELOPMENT ENVIRONMENT
    : 'https://apis.lazylawn.ca/api/v1'; // PRODUCTION ENVIRONMENT

export const generalAxios = axios.create({
  baseURL: baseUrl,
});

/**
 * @name keapInterceptor
 * @description - This function is used to set the axios interceptor for all requests and responses to keap.
 * @returns {void}
 * */

const keapInterceptor = () => {
  // Add a request interceptor
  generalAxios.interceptors.request.use(
    (config) => {
      // Do something before request is sent
      config.headers = {
        ...config.headers,
        'Content-type': 'application/json',
        'x-lazylawn-api-key': encryptLazySecuritySeal(),
      };
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    },
  );

  // Add a response interceptor
  generalAxios.interceptors.response.use(
    (response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    (error) => {
      const { config } = error;
      if (
        error?.response &&
        (error?.response?.status === 401 || error?.response?.status === 429) &&
        !config._retry
      ) {
        config._retry = true;
        return generalAxios(config);
      }
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    },
  );
};

export default keapInterceptor;
