// eslint-disable-react-function-component-definition

import NotFoundPage from 'Components/Pages/404';
import { TRACKING_ID } from 'Constant';
import React, { Suspense } from 'react';
import ReactGA from 'react-ga';
import { Route, Routes } from 'react-router-dom';
import { useOnlineStatus } from 'hooks';
import { Loader } from 'Components/Commons';
import { generalInterceptor } from 'Adapters/Interceptors';
import { Calendly, EstimatePage, HomePage, ManualEstimate, ThankYou } from '../Components/Pages';

ReactGA.initialize(TRACKING_ID);

const AppRoutes = () => {
  useOnlineStatus();
  generalInterceptor();

  return (
    <Suspense
      fallback={
        <div className='flex min-h-screen  items-center justify-center'>
          <Loader />
        </div>
      }
    >
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/estimate/:estimateIdEncrypted' element={<EstimatePage />} />
        <Route path='/book-site-visit' element={<Calendly />} />
        <Route path='/thank-you' element={<ThankYou />} />
        <Route path='/manual-estimate' element={<ManualEstimate />} />
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
