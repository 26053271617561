import { LAZYLAWN_APIS } from 'Adapters';
import { Button, ButtonLoader } from 'Components/Commons';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

export const EstimateFeedBack = ({ contactId, setRefresh, refresh, tags, fetchingKeapContact }) => {
  const [loading, setLoading] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);

  const handleEstimateFeedback = async (tagId) => {
    setSelectedTag(tagId);
    setLoading(true);
    try {
      await LAZYLAWN_APIS.applyTagsToContact(tagId, contactId);
      setRefresh(!refresh);
      setSelectedTag(null);
      toast.success('Thank you for your feedback');
    } catch (error) {
      toast.error('Something went wrong, please try again later');
      setSelectedTag(null);
    }
  };

  const feedbackButtons = [
    {
      id: '1066',
      label: "Yes, let's turf it later this season",
    },
    {
      id: '1072',
      label: 'Not now, maybe Next Season',
    },
    {
      id: '236',
      label: 'Ouch, Out of my Budget',
    },
  ];

  const tagsArray = feedbackButtons.filter((tag) => tags?.indexOf(tag.id) === -1);

  if (tagsArray.length < 1) return null;

  return (
    <div className='estimate-actions'>
      <div className='py-2'>
        <h1 className='text-xl font-bold text-left mb-3 text-secondary font-Monteserrat'>
          Are you ready to turf it?
        </h1>
        <div className='estimate-feedback md:mb-4 gap-2'>
          {tagsArray.map((btn) => (
            <Button
              id={btn.id}
              onClick={() => handleEstimateFeedback(btn.id)}
              key={btn.id}
              disabled={fetchingKeapContact || (btn.id === selectedTag && loading)}
              customClass='min-w-[200px]'
              variant='outline-primary'
            >
              {btn.id === selectedTag && loading ? <ButtonLoader /> : btn.label}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};
