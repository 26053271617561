/**
 * Handle all the API calls to handle general
 * @name generalAdapter
 * @param {(config: RequestConfig)=> any} requestor
 */
const generalAdapter = (requestor) => ({
  /**
   * @name generateEstimate
   * @description - Generate an estimate in freshbook
   * @param {object} data - data to update
   * @param {boolean} isManual - boolean to check if the estimate is manual
   * @returns {<Promise>} - Promise object represents the response from the server
   * */
  generateEstimate: async (data, isManual) => {
    let url = `/generateEstimate`;
    if (isManual) {
      url = `/generateEstimate?type=${isManual}`;
    }
    const response = await requestor({
      method: 'POST',
      url,
      data,
    });
    return response;
  },

  /**
   * @name acceptFreshbookEstimate
   * @param {string} estimateId - freshbook estimate id
   * @returns {<Promise>} - Promise object represents the response from the server
   * */
  acceptFreshbookEstimate: async (estimateId) => {
    const url = `/updateEstimate/accept`;
    const response = await requestor({
      method: 'PATCH',
      url,
      data: { encryptedEstimateId: estimateId },
    });
    return response;
  },

  /**
   * @name createClientInvoice
   * @param {string} encryptedEstimateId - freshbook estimate id
   * @returns {<Promise>} - Promise object represents the response from the server
   * */
  createClientInvoice: async (encryptedEstimateId) => {
    const url = `/createInvoice`;
    const response = await requestor({
      method: 'POST',
      url,
      data: { encryptedEstimateId },
    });
    return response;
  },

  /**
   * Apply tags to contact
   * @name applyTagsToContact
   * @param {string} tagId - keap tag id
   * @param {array} data.contactIds - array of contact ids to which a tag is to be applied
   * @returns {Promise} - Promise object represents the response from the server
   *
   */

  applyTagsToContact: async (tagId, contactId) => {
    const data = [`${contactId}`];
    const url = `/keap/contacts/${tagId}/tags`;
    const response = await requestor({
      method: 'POST',
      url,
      data,
    });
    return response;
  },

  removeTagsFromContact: async (tagId, contactId) => {
    const data = [`${contactId}`];
    const url = `/keap/contacts/${tagId}/tags`;
    const response = await requestor({
      method: 'PUT',
      url,
      data,
    });
    return response;
  },

  updateKeapCustomFields: async (contactId, customFields) => {
    const url = `/updateKeapCustomFields/${contactId}`;
    const response = await requestor({
      method: 'PATCH',
      url,
      data: customFields,
    });
    return response;
  },

  /**
   * @name get - handle all get requests for keap contact
   * @param {string} clientId - keap contact id
   * @returns {Promise} - Promise object represents the response from the server
   */
  get: {
    /**
     * @name getEstimate
     * @description - Get the estimate details for the estimateId provided
     * @returns {Promise} - Promise object represents the response from the server
     */
    getEstimate: async (estimateId) => {
      const url = `/getEstimate?estimateId=${estimateId}`;
      const response = await requestor({
        method: 'GET',
        url,
      });
      return response;
    },

    /**
     * @name getEstimateData
     * @description - Get the estimate data like invoice status, keap contact, calendly link for the estimateId, customerId and email provided
     * @param {string} estimateId - estimate id
     * @param {string} customerId - customer id
     * @param {string} email - email
     * @returns {Promise} - Promise object represents the response from the server
     */
    getEstimateData: async (estimateId, customerId, email) => {
      const url = `/getEstimateData?estimateId=${estimateId}&customerId=${customerId}&email=${email}`;
      const response = await requestor({
        method: 'GET',
        url,
      });
      return response;
    },

    /**
     * @name getContactTags
     * @description - Get the tags for the contact using the email provided
     * @param {string} email - email
     * @returns {Promise} - Promise object represents the response from the server
     */
    getContactTags: async (email) => {
      const url = `/getContactTags?email=${email}`;
      const response = await requestor({
        method: 'GET',
        url,
      });
      return response;
    },

    /**
     * Get contact using the contact email
     * @name getKeapClientByEmail
     * @param {string} email - keap contact email
     * @returns {Promise} - Promise object represents the response from the server
     */
    getKeapClientByEmail: async (email) => {
      if (!email) return Promise.reject(new Error('Email is required'));
      const url = `/keap/contacts/getByEmail?email=${email?.trim()}`;
      const response = await requestor({
        method: 'GET',
        url,
      });
      return response;
    },
  },
});

export default generalAdapter;
