import { Button } from 'Components/Commons';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <div className='notfound-banner w-full min-w-full min-h-screen flex justify-center items-center'>
      <div className='flex justify-center items-center min-h-screen md:min-h-fit p-1 md:p-5 text-white mx-0 md:mx-auto my-0 md:my-4 rounded-md w-full md:w-2/5'>
        <div>
          <div className='flex justify-center p-3 mb-3'>
            <img
              src={`${process.env.PUBLIC_URL}/assets/logos/lazylawn-logo.png`}
              alt='Lazy Lawn®'
              className='w-80'
            />
          </div>
          <h1 className='text-center font-lawn text-4xl font-bold'>Oops! Page Not Found</h1>

          <div className='flex flex-col md:flex-row justify-center p-3 mt-4 gap-3'>
            <Button variant='primary' onClick={() => navigate('/')}>
              Generate Estimate
            </Button>
            <Button
              variant='secondary'
              onClick={() => {
                window.location.href = 'https://lazylawn.ca/services';
                window.location.target = '_blank';
              }}
            >
              View More services
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
