/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-this-in-sfc */
import useAnalyticsEventTracker from 'hooks/useAnalyticsEventTracker';
import React, { useEffect, useState } from 'react';

let marker = [];
let polygon_area;
let marker_no = 0;
let marker_count = 0;
let map;

const labels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

const MapArea = ({ setFieldValue, address, isLoadingAddress }) => {
  const [showMap, setShowMap] = useState(false);
  const gaEventTracker = useAnalyticsEventTracker('New Estimates Quotes');

  function displaySearchResults(mp, autoComplete, markers) {
    const place = autoComplete?.getPlace();
    if (!place) {
      return;
    }
    const newObj = {};
    place?.address_components.forEach((item) => {
      const key = item.types[0];
      const value = item.long_name;
      newObj[key] = value;
    });
    setFieldValue('address', newObj);
    gaEventTracker(place.name);

    // Clear out the old markers.
    markers.forEach((m) => {
      m.setMap(null);
    });

    // For each place, get the icon, name and location.
    const bounds = new window.google.maps.LatLngBounds();

    if (!place.geometry) {
      // console.log('Returned places contains no geometry');
      return;
    }
    const icon = {
      url: place.icon,
      size: new window.google.maps.Size(71, 71),
      origin: new window.google.maps.Point(0, 0),
      anchor: new window.google.maps.Point(17, 34),
      scaledSize: new window.google.maps.Size(25, 25),
    };

    // Create a marker for each places.
    markers.push(
      new window.google.maps.Marker({
        map: mp,
        icon,
        title: place.name,
        position: place.geometry.location,
      }),
    );

    if (place.geometry.viewport) {
      // Only geocodes have viewport.
      bounds.union(place.geometry.viewport);
    } else {
      bounds.extend(place.geometry.location);
    }

    map.setZoom(17);
    map.fitBounds(bounds);
  }

  const draw_polygon = () => {
    if (marker_count >= 2) {
      if (polygon_area) {
        polygon_area.setMap(null);
      }
      const polygon = [];
      for (let i = 0; i < marker_count; i++) {
        polygon[i] = new window.google.maps.LatLng(
          marker[i].position.lat(),
          marker[i].position.lng(),
        );
      }

      polygon_area = new window.google.maps.Polygon({
        paths: polygon,
        strokeColor: '#00B300',
        strokeOpacity: 0.5,
        strokeWeight: 2,
        fillColor: '#00B300',
        fillOpacity: 0.3,
      });

      polygon_area.setMap(map);
      const total_area = window.google.maps.geometry.spherical.computeArea(polygon_area.getPath());
      const totalArea = (total_area * 10.764).toFixed(2);
      setFieldValue('estimateArea', totalArea);
    }
  };

  function drag_marker(e) {
    const latlng = e.latLng;
    const lat = latlng.lat();
    const lng = latlng.lng();
    const new_latlng = new window.google.maps.LatLng(lat, lng);
    marker[this.marker_id].setPosition(new_latlng);
    draw_polygon();
  }

  const clickMapHandler = (e) => {
    const latlng = e.latLng;
    marker[marker_no] = new window.google.maps.Marker({
      position: latlng,
      map,
      marker_id: marker_no,
      title: `marker_${marker_no}${labels[marker_no % labels.length]}`,
      label: labels[marker_no % labels.length],
      draggable: true,
    });
    window.google.maps.event.addListener(marker[marker_no], 'drag', drag_marker);
    marker_no++;
    marker_count++;
    draw_polygon();
  };

  useEffect(() => {
    function initMap() {
      const start_latitude = 44.012893;
      const start_longitude = -79.441833;

      const latlng = new window.google.maps.LatLng(start_latitude, start_longitude);
      const opts = {
        tilt: 0,
        zoom: 17,
        center: latlng,
        mapTypeId: window.google.maps.MapTypeId.HYBRID,
        disableDefaultUI: true,
        zoomControl: true,
        scaleControl: true,
        rotateControl: true,
        fullscreenControl: true,
        gestureHandling: 'cooperative',
      };

      map = new window.google.maps.Map(document.getElementById('map'), opts);
      window.google.maps.event.addListener(map, 'click', clickMapHandler);

      const input = document.getElementById('manual-input');
      const options = {
        componentRestrictions: { country: ['us', 'ca'] },
        // fields: ['address_components', 'geometry', 'icon', 'name', 'formatted_address'],
        // strictBounds: false,
        // types: ['address'],
      };
      const autocomplete = new window.google.maps.places.Autocomplete(input, options);

      const placeMarker = new window.google.maps.Marker({
        map,
        anchorPoint: new window.google.maps.Point(0, -29),
      });

      let markers = [];
      map.addListener('bounds_changed', () => {
        autocomplete.setBounds(map.getBounds());
      });

      autocomplete.addListener('place_changed', () => {
        for (let i = 0; i < marker_count; i++) {
          marker[i].setMap(null);
        }
        if (polygon_area) {
          polygon_area.setMap(null);
        }
        marker_count = 0;
        marker = [];
        marker_no = 0;
        setFieldValue('estimateArea', '');
        placeMarker.setVisible(false);

        const place = autocomplete.getPlace();

        if (!place.geometry || !place.geometry.location) {
          // User entered the name of a Place that was not suggested and
          // pressed the Enter key, or the Place Details request failed.
          return;
        }

        // If the place has a geometry, then present it on a map.
        if (place.geometry.viewport) {
          map.fitBounds(place.geometry.viewport);
        } else {
          map.setCenter(place.geometry.location);
          map.setZoom(17); // Why 17? Because it looks good.
        }

        placeMarker.setPosition(place.geometry.location);
        placeMarker.setVisible(true);

        const newObj = {};
        place?.address_components.forEach((item) => {
          const key = item.types[0];
          const value = item.long_name;
          newObj[key] = value;
        });
        setFieldValue('address', newObj);

        // Clear out the old markers.
        markers.forEach((m) => {
          m.setMap(null);
        });
        markers = [];

        // For each place, get the icon, name and location.
        const bounds = new window.google.maps.LatLngBounds();

        if (!place.geometry) {
          // console.log('Returned place contains no geometry');
          return;
        }
        const icon = {
          url: place.icon,
          size: new window.google.maps.Size(71, 71),
          origin: new window.google.maps.Point(0, 0),
          anchor: new window.google.maps.Point(17, 34),
          scaledSize: new window.google.maps.Size(25, 25),
        };

        // Create a marker for each place.
        markers.push(
          new window.google.maps.Marker({
            map,
            icon,
            title: place.name,
            position: place.geometry.location,
          }),
        );

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }

        map.fitBounds(bounds);
        const searchBtn = document.getElementById('open-map');

        searchBtn.onclick = () => {
          searchBtn.style.display = 'none';
          setShowMap(true);
          document.getElementById('maparea').style.display = 'block';
          displaySearchResults(map, autocomplete, markers);
        };
      });
    }
    let ignore = false;

    if (!ignore) initMap();
    return () => {
      ignore = true;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const confirmAreaSelection = () => {
    const mapArea = document.getElementById('maparea');
    const searchBtn = document.getElementById('open-map');
    mapArea.style = 'display:none';
    searchBtn.style.display = 'block';
    setShowMap(false);
  };

  const clearSelectedArea = () => {
    for (let i = 0; i < marker_count; i++) {
      marker[i].setMap(null);
    }
    if (polygon_area) {
      polygon_area.setMap(null);
    }
    marker_count = 0;
    marker = [];
    marker_no = 0;
    setFieldValue('estimateArea', '');
  };

  useEffect(() => {
    if (address?.locality) {
      const mapInput = document.getElementById('manual-input');
      mapInput.value = `${address?.locality}, ${address?.administrative_area_level_1} ${
        address?.postal_code
      }${address?.country ? `, ${address?.country}` : ''}`;

      // Initialize the Geocoding service
      const geocoder = new window.google.maps.Geocoder();

      // Define the address you want to center the map on
      const newAddress = `${address?.locality}, ${address?.administrative_area_level_1} ${address?.postal_code}, ${address?.country}`;

      // Use the Geocoding service to convert the address to a geographic location
      geocoder.geocode({ address: newAddress }, (results, status) => {
        if (status === 'OK') {
          // If the Geocoding service returns a valid result, extract the location
          const location = results[0].geometry.location;

          const opts = {
            tilt: 0,
            zoom: 20,
            center: location,
            mapTypeId: window.google.maps.MapTypeId.HYBRID,
            disableDefaultUI: true,
          };
          map = new window.google.maps.Map(document.getElementById('map'), opts);
          window.google.maps.event.addListener(map, 'click', clickMapHandler);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address?.locality, address?.country]);

  return (
    <div className='flex justify-center items-center flex-col w-full'>
      <div className='flex justify-between flex-col md:flex-row w-full'>
        <div className={`w-full md:${showMap ? 'w-full' : 'w-3/4'}`}>
          <input
            required=''
            autoComplete='off'
            name='address'
            type='text'
            data-hj-whitelist='true'
            className='appearance-none autocomplete-input font-Monteserrat bg-[#f5f8fa] box-border border border-[#cbd6e2] w-full rounded-full select-none py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-primary mt-2'
            placeholder='Please enter address to start'
            id='manual-input'
            style={{ outline: 'none' }}
            disabled={isLoadingAddress}
          />
        </div>
        <div className='flex'>
          <button
            type='button'
            id='open-map'
            disabled={isLoadingAddress}
            className='bg-white text-secondary border-secondary hover:bg-secondary hover:text-white hover:border-secondary disabled:bg-white disabled:text-tertiary disabled:hover:text-tertiary disabled:border-tertiary font-bold text-lg cursor-pointer rounded-full uppercase py-1 px-4 mt-2 font-Monteserrat border-2  hover:bg-secondary/80  hover:border-opacity-0 disabled:cursor-not-allowed'
          >
            {isLoadingAddress ? 'Loading...' : 'Open Map'}
          </button>
        </div>
      </div>
      <div className='map-area font-Monteserrat w-full' id='maparea'>
        <p className='font-bold py-2 text-textGreen'>Zoom in to pinpoint the desired turf area.</p>
        <p className='map_desc text-center text-darkGreen  text-sm py-2'>
          Click on at least 3 places on the map to plot the square footage
        </p>
        <div
          id='map'
          className='mb-5 rounded-t'
          style={{ minHeight: '500px', width: '100%', margin: '0px auto' }}
        />
        <div className='flex gap-1'>
          <button
            onClick={clearSelectedArea}
            type='button'
            disabled={marker_count < 3}
            className='bg-secondary text-white font-bold text-lg cursor-pointer uppercase py-1 px-2 font-Monteserrat border-1 border-secondary hover:bg-secondary/80 hover:text-white rounded-b-md hover:border-opacity-0 disabled:bg-tertiary disabled:border-tertiary disabled:cursor-not-allowed'
          >
            Clear map
          </button>
          {marker_count > 2 && (
            <button
              onClick={confirmAreaSelection}
              disabled={marker_count < 3}
              type='button'
              className='bg-secondary text-white font-bold text-lg cursor-pointer uppercase py-1 px-2 font-Monteserrat border-1 border-secondary hover:bg-secondary/80 hover:text-white rounded-b-md hover:border-opacity-0 disabled:bg-tertiary disabled:border-tertiary disabled:cursor-not-allowed'
            >
              Confirm Area
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default MapArea;
