import { retrieveCalendlyEvent } from 'Adapters';
import { Button } from 'Components/Commons';
import React, { useEffect, useState } from 'react';
import { MdDone, MdCalendarToday, MdOutlineAccessTime } from 'react-icons/md';
import { FaGlobeAmericas } from 'react-icons/fa';
import './style.css';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

export const ThankYou = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [eventDetails, setEventDetails] = useState({});
  const [meetingName, setMeetingName] = useState('');
  const eventUrl = localStorage.getItem('eventUrl');
  const estimateUrl = localStorage.getItem('estimateUrl');

  useEffect(() => {
    const getEventDetails = async () => {
      if (!eventUrl) return;
      setLoading(true);
      const res = await retrieveCalendlyEvent(eventUrl);
      setEventDetails(res);
      const res2 = await retrieveCalendlyEvent(res?.event_type);
      setMeetingName(res2?.profile?.name);
      setLoading(false);
    };

    getEventDetails();
  }, [eventUrl]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formatTime = (time) => new Date(time).toLocaleTimeString()?.substring(0, 4);
  const meetingTime =
    new Date(eventDetails?.end_time).getMinutes() - new Date(eventDetails?.start_time).getMinutes();

  return (
    <div>
      <div className='thank-you-page-wrapper font-poppins'>
        <div className='thank-you-page'>
          <div className='thank-you-page-content'>
            <div className='thank-you-page-header flex flex-col justify-center items-center'>
              <div className='thank-you-page-header-status'>
                <MdDone className='text-primary text-4xl' />
              </div>
              <div className='text-primary font-bold text-xl'>
                <h1>Site Booking Confirmed</h1>
              </div>
            </div>

            <div className='thank-you-page-body'>
              <div className='mb-5 py-3 mt-2 rounded-md'>
                {loading ? (
                  <div className='text-secondary italic'>Loading event details...</div>
                ) : (
                  <div className=''>
                    {eventDetails?.name && (
                      <>
                        <div className='flex items-center gap-1'>
                          <div
                            style={{
                              backgroundColor: '#ff4f00',
                              width: '20px',
                              height: '20px',
                              borderRadius: '50%',
                            }}
                          />
                          <h2 className='text-lg font-semibold'>{eventDetails?.name}</h2>
                        </div>
                        {meetingName && (
                          <div className='calender-item flex items-center gap-2'>
                            <FaGlobeAmericas />
                            {meetingName}
                          </div>
                        )}
                        <div className='calender-item flex items-center gap-2'>
                          <MdCalendarToday />
                          {formatTime(eventDetails?.start_time)} -{' '}
                          {formatTime(eventDetails?.end_time)},{' '}
                          {moment(eventDetails?.end_time).format('dddd, MMMM, DD, YYYY')}
                        </div>
                        <div className='calender-item flex items-center gap-2'>
                          <MdOutlineAccessTime />
                          {meetingTime >= 0 ? meetingTime : meetingTime * -1} Minutes
                        </div>
                      </>
                    )}
                    <div className='pt-2'>
                      A calendar invitation has been sent to your email address.
                    </div>
                  </div>
                )}
              </div>
              <div className='lazy-lawn-thank-you-content'>
                <h3 className='mb-4'>
                  Thank you for your Interest in
                  <span className='font-semibold' style={{ fontSize: '20px', color: '#ff4f00' }}>
                    {' '}
                    Lazy Lawn®
                  </span>
                </h3>
                <p>
                  One of our representatives will be in touch with you shortly to discuss your
                  project. We typically respond to questions and estimate requests within 1-2
                  business days.
                </p>
                <p>
                  We generally start the process by providing clients with a rough estimate using
                  Google Maps, then if you’d like to proceed one of our representatives will book an
                  appointment with you for an on site visit. We’ll verify measurements and show you
                  all of our newest grasses!
                </p>
                <p>
                  <span style={{ fontSize: '15pt', color: '#ff4f00', fontWeight: 'bold' }}>
                    Lazy Lawn® Kicks Grass!
                  </span>
                </p>
              </div>
            </div>
            <div className='thank-you-page-footer py-5 flex flex-col justify-center gap-4 sm:flex-row'>
              <Button
                variant='outline-primary'
                onClick={() => {
                  navigate(`/estimate/${estimateUrl}`);
                  localStorage.removeItem('eventUrl');
                  localStorage.removeItem('estimateUrl');
                }}
              >
                View Estimate
              </Button>
              <Button
                variant='outline-primary'
                onClick={() => {
                  localStorage.removeItem('eventUrl');
                  localStorage.removeItem('estimateUrl');
                  window.location.href = 'https://lazylawn.ca/services';
                  window.location.target = '_blank';
                }}
              >
                View More services
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
