/* eslint-disable prefer-destructuring */
import { ROUGH_ESTIMATE_ITEMS } from 'Constant';
import CryptoJS from 'crypto-js';

export const isValidEmail = (email) => {
  if (
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
      email,
    )
  ) {
    return true;
  }
  return false;
};

/**
 * @name getEstimateInvoiceStatus
 * @param {object} estimate
 * @returns {boolean} readyToInvoice status (true || false)
 */
export const getEstimateInvoiceStatus = (estimate) => {
  if (!estimate?.accepted) return false;
  let readyToInvoice = true;
  estimate?.lines?.forEach((item) => {
    Object.keys(ROUGH_ESTIMATE_ITEMS)?.forEach((key) => {
      if (
        item.name === ROUGH_ESTIMATE_ITEMS[key].name ||
        item.description === ROUGH_ESTIMATE_ITEMS[key].description
      ) {
        readyToInvoice = false;
      }
    });
  });
  return readyToInvoice;
};

export const encryptLazySecuritySeal = () => {
  const SP = process.env.REACT_APP_LAZY_SECURITY_SECRET;
  const LS = process.env.REACT_APP_LAZYLAWN_SECURITY_CODE;
  const currentTime = new Date().getTime();
  const expirationTime = currentTime + 600 * 1000; // 10 minutes

  const tokenPayload = `${LS}.${expirationTime}`;
  return CryptoJS.AES.encrypt(tokenPayload, SP).toString();
};

/**
 * @name separateStreetAndRoute
 * @description This function is used to separate the digits from the string
 * @param {string} street
 * @returns {object} street_number, route
 * */

export const separateStreetAndRoute = (street) => {
  if (!street) return { street_number: '', route: '' };
  let digits = '';
  let rest = '';

  /* eslint-disable no-restricted-globals */
  for (let i = 0; i < street.length; i++) {
    if (!isNaN(street[i])) {
      digits += street[i];
    } else {
      rest = street.substring(i);
      break;
    }
  }

  return {
    street_number: digits.trim(),
    route: rest.trim(),
  };
};

/**
 * @name getCloudFlareImageUrl
 * @param {string} imageId
 * @returns {string} cloudFlareImageUrl
 * @description Get the cloud flare image url for the given imageId
 */
export const getCloudFlareImageUrl = (imageId) => {
  const CLOUD_FLARE_URL = 'https://imagedelivery.net/ymzsI53Kh75k7cihPmB4TQ';
  return `${CLOUD_FLARE_URL}/${imageId}/public`;
};
