import React from 'react';

const ButtonMailto = ({ mailto, label }) => (
  <button
    type='button'
    className='btn btn-primary cursor-pointer hover:text-secondary'
    onClick={(e) => {
      window.location.href = mailto;
      e.preventDefault();
    }}
  >
    {label}
  </button>
);

export default ButtonMailto;
