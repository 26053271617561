import React, { useEffect } from 'react';
import { useMediaQuery } from '../../../hooks';

const MapArea = React.forwardRef(({ step, selectedEstimateArea, setFieldValue }, ref) => {
  const { isMobile } = useMediaQuery();

  useEffect(() => {
    if (selectedEstimateArea) {
      setFieldValue('estimateArea', selectedEstimateArea);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEstimateArea]);

  return (
    <div
      style={{
        display: step === 1 ? 'block' : 'none',
      }}
      className='w-full'
    >
      <div className='mt-3'>
        <div
          id='step1'
          style={{
            padding: isMobile ? '5px 20px' : '0px 0px 0px 0px',
            width: '100%',
          }}
          className='flex flex-col justify-center items-center'
        >
          <input
            ref={ref}
            required=''
            autoComplete='off'
            name='address'
            type='text'
            data-hj-whitelist='true'
            className='appearance-none autocomplete-input font-Monteserrat bg-[#f5f8fa] box-border border border-[#cbd6e2] w-full md:w-4/5 rounded-full select-none py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-primary mt-2'
            placeholder='Please enter address to start'
            id='pac-input'
            style={{ outline: 'none' }}
          />
          <div className='flex justify-center'>
            <button
              type='button'
              id='get-start'
              className='bg-secondary text-white font-bold text-lg w-[12rem] cursor-pointer rounded-full uppercase py-2 px-5 mt-8 font-Monteserrat border-2 border-secondary hover:bg-secondary/80 hover:text-white hover:border-opacity-0 disabled:bg-tertiary disabled:border-tertiary disabled:cursor-not-allowed'
            >
              Get Started
            </button>
          </div>
          <div className='map-area font-Monteserrat w-full' id='maparea'>
            <p className='font-bold py-3 text-textGreen '>
              Zoom in to pinpoint your desired turf area.
            </p>
            <p className='map_desc text-center text-darkGreen  text-sm py-2'>
              Click on at least 3 places on the map to plot your square footage
            </p>
            <div
              id='map'
              className='mb-5 rounded'
              style={{ minHeight: '500px', width: '100%', margin: '0px auto' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default MapArea;
