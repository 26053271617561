import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

const useOnlineStatus = () => {
  // Online state
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
      if (!navigator.onLine) {
        toast('You are offline. Please connect to the internet for better performance.', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 60000,
          hideProgressBar: false,
          closeOnClick: false,
          newestOnTop: true,
          pauseOnHover: true,
          rtl: false,
          pauseOnFocusLoss: true,
          theme: 'light',
        });
      }
    };

    // Listen to the online status
    window.addEventListener('online', handleStatusChange);

    // Listen to the offline status
    window.addEventListener('offline', handleStatusChange);

    // Specify how to clean up after this effect for performance improvement
    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    };
  }, [isOnline]);

  return isOnline;
};

export default useOnlineStatus;
