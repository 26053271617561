import Input from 'Components/Commons/InputField/Input';
import { PageLoader } from 'Components/Commons';
import React from 'react';
import { useMediaQuery } from '../../../hooks';
import './style.css';

const ContactForm = ({ step, loading }) => {
  const { isMobile } = useMediaQuery();
  return (
    <div
      className='w-full'
      id='step5'
      style={{
        display: step === 5 ? 'block' : 'none',
      }}
    >
      <div className='w-full'>
        <div
          className='text-center pb-2'
          style={{
            padding: isMobile ? '0 1rem' : '0 5rem',
          }}
        >
          <h3
            className='text-[26px] font-bold text-secondary font-Monteserrat'
            style={{
              lineHeight: '2rem',
            }}
          >
            Your quote will be ready in 5 seconds
          </h3>
          <h4 className='text-textGreen font-Monteserrat mt-5 text-center font-bold'>
            Please Complete the form
          </h4>
        </div>

        <div className='p-4'>
          <div className='font-poppins bg-[#fff] p-3 rounded-md  diy-form relative'>
            {loading && (
              <div className='absolute top-0 bottom-0 left-0 right-0 rounded-md w-full bg-black/30 z-10 flex justify-center items-center'>
                <PageLoader />
              </div>
            )}
            <div className='input-field-wrapper'>
              <div className='input-field-wrapper__item'>
                <Input
                  name='fname'
                  type='text'
                  label='First Name'
                  id='fname'
                  placeholder='Your name..'
                  disabled={loading}
                />
              </div>
              <div className='input-field-wrapper__item'>
                <Input
                  name='lname'
                  type='text'
                  label='Last Name'
                  placeholder='Your last name..'
                  disabled={loading}
                />
              </div>
              <div className='input-field-wrapper__item'>
                <Input
                  name='email'
                  type='email'
                  label='Email'
                  id='email'
                  placeholder='john@gmail.com'
                  disabled={loading}
                />
              </div>
              <div className='input-field-wrapper__item'>
                <Input
                  label='Phone'
                  type='tel'
                  id='phone'
                  name='phone'
                  placeholder='123-456-7890'
                  disabled={loading}
                />
              </div>
            </div>

            <div className='input-field-wrapper__item-estimate-area'>
              <Input label='Area(Sq/ft)' name='estimateArea' type='number' disabled={loading} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
