import { GRASS_TYPES_OPTIONS, INSTALLATION_OPTIONS, grassTypes } from 'Constant';
import React from 'react';
import { getCloudFlareImageUrl } from 'Utils';
import { useMediaQuery } from '../../../hooks';
import classes from './styles.module.css';

const GrassType = ({ grass, handleGrassSelection, isSelected }) => {
  const { name, image, faceHeight, faceWeight, price } = grass;
  return (
    <div
      onClick={handleGrassSelection}
      className={`flex flex-col cursor-pointer ${classes.grass} ${
        isSelected ? classes.selected_grass : ''
      }`}
    >
      <img decoding='async' src={getCloudFlareImageUrl(image)} alt={name} />
      <div className='flex flex-col items-center'>
        <h3 className='font-bold mt-1 text-lg text-center text-textGreen font-lawn'>{name}</h3>
        <p className='text-sm text-[#666] mb-0 font-semibold'>{`${faceHeight}"`}</p>
        <p className='text-sm text-[#666] mb-2 font-semibold'>{faceWeight}</p>
        <p className={`font-Monteserrat px-5 py-[0.5] text-sm text-center ${classes.grass_price}`}>
          ${price} SQ/FT
        </p>
      </div>
    </div>
  );
};

const GrassTypes = ({ step, setStep, values, setFieldValue }) => {
  const { isMobile } = useMediaQuery();

  const getGrassTypes = () => {
    if (values.installationOption === INSTALLATION_OPTIONS.DIY) return grassTypes;
    return grassTypes.filter((gras) => gras.name !== GRASS_TYPES_OPTIONS.PUTTING_GREEN);
  };

  return (
    <div
      style={{
        display: step === 4 ? 'block' : 'none',
        padding: isMobile ? '0 1rem' : '0 5rem',
      }}
    >
      <div className='text-center py-3'>
        <h3 className='text-[26px] font-bold text-secondary font-lawn'>Select Your Awesome Turf</h3>
        <h4 className='text-darkGreen mt-2 text-center font-bold text-sm font-Monteserrat '>
          You can always change your mind later
        </h4>
      </div>

      <div className='flex gap-2 mb-3 mt-2 flex-wrap justify-center'>
        {getGrassTypes().map((grass) => (
          <GrassType
            grass={grass}
            key={grass.name}
            isSelected={values.grass === grass.name}
            handleGrassSelection={() => {
              setStep(5);
              setFieldValue('grass', grass.name);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default GrassTypes;
