import React from 'react';
import { DYESection } from '../../Sections';

export const HomePage = () => (
  <div className='home-banner w-full min-w-full min-h-screen flex justify-center items-center'>
    <div className='flex justify-center items-center min-h-screen md:min-h-fit p-1 md:p-5 bg-white mx-0 md:mx-auto my-0 md:my-4 rounded-md w-full md:w-3/4'>
      <DYESection />
    </div>
  </div>
);
