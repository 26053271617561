import React from 'react';
import './Loader.css';

const PageLoader = () => (
  <div className='page-spinner'>
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
  </div>
);

export default PageLoader;
