import React from 'react';
import { Field } from 'formik';

const InputType = ({
  field,
  type,
  values,
  name,
  setFieldValue,
  placeholder,
  options,
  disabled,
  customOnChange,
  rows,
  className,
  ...props
}) => {
  switch (type) {
    case 'textarea':
      return (
        <textarea
          disabled={disabled}
          placeholder={placeholder}
          value={values[name]}
          rows={rows}
          {...field}
          className={`${className} rounded-md`}
        />
      );
    case 'select':
      return (
        <select
          disabled={disabled}
          value={values[name]}
          onChange={(e) => {
            setFieldValue(name, e.target.value);
            if (customOnChange) {
              customOnChange(e);
            }
          }}
          className='appearance-none bg-[#f5f8fa] box-border border border-[#cbd6e2] rounded-full select-none py-2 px-3 text-gray-700 leading-tight focus:outline-none  mt-2'
          {...field}
        >
          {options?.map((option) => (
            <option value={option?.value} key={option?.value}>
              {option?.label}
            </option>
          ))}
        </select>
      );
    default:
      return (
        <input
          {...field}
          className={className}
          placeholder={placeholder}
          value={values[name]}
          type={type}
          disabled={disabled}
          {...props}
        />
      );
  }
};

const Input = ({
  name,
  placeholder,
  type,
  label,
  disabled,
  rows,
  customClass,
  note,
  validate,
  ...props
}) => (
  <Field name={name} validate={validate}>
    {({ field, meta, form: { values, setFieldValue } }) => (
      <div className='flex flex-col w-full'>
        {label ? (
          <label htmlFor={name} className='text-darkGreen font-Monteserrat capitalize'>
            {label}{' '}
            {note && (
              <span
                className='text-muted'
                style={{
                  fontSize: '10px',
                }}
              >
                ({note})
              </span>
            )}
          </label>
        ) : null}

        <InputType
          type={type}
          field={field}
          values={values}
          name={name}
          setFieldValue={setFieldValue}
          placeholder={placeholder}
          disabled={disabled}
          rows={rows}
          {...props}
          className={`appearance-none bg-[#f5f8fa] box-border border border-[#cbd6e2] rounded-full select-none py-2 px-3 text-gray-700 leading-tight focus:outline-none mt-2 ${customClass} ${
            meta.touched && meta.error
              ? ' invalid-field focus:border-secondary border-secondary'
              : ' focus:border-primary border-[#cbd6e2]'
          }`}
        />

        {meta.touched && meta.error && (
          <div
            style={{ color: '#d32f2f', fontSize: '12px', paddingLeft: 3 }}
            className='font-poppins pt-1 font-semibold'
          >
            {meta.error}
          </div>
        )}
      </div>
    )}
  </Field>
);

export default Input;
