import { Helmet } from 'react-helmet';
import React, { useEffect, useState } from 'react';
import { Button, ButtonLoader, PageLoader } from 'Components/Commons';
import { useMediaQuery } from 'hooks';
import { FooterSection } from 'Components/Sections';
import { Formik } from 'formik';
import { manualEstimateValidationSchema } from 'Components/Commons/Schema';
import { useNavigate } from 'react-router-dom';
import useAnalyticsEventTracker from 'hooks/useAnalyticsEventTracker';
import { LAZYLAWN_APIS } from 'Adapters';
import { toast } from 'react-toastify';
import ManualEstimateForm from './Forms/ManualEstimateForm';

export const ManualEstimate = () => {
  const [loading, setLoading] = useState(false);
  const { isMobile } = useMediaQuery();
  const navigate = useNavigate();
  const gaEventTracker = useAnalyticsEventTracker('Manual Estimates Quotes');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleManualEstimateFormSubmission = async (values) => {
    if (!navigator.onLine) {
      toast.error('You are offline. Please connect to the internet to generate estimate.');
      return;
    }
    try {
      setLoading(true);
      const res = await LAZYLAWN_APIS.generateEstimate(values, 'manual');
      if (res.data?.estimateUrl) {
        navigate(`/estimate/${res.data.estimateUrl}?manual=true`);
        gaEventTracker('Manual Estimate Generated', res.data.estimateUrl);
      }
    } catch (error) {
      setLoading(false);
      toast.error('Something went wrong, Please try again later');
    }
  };

  return (
    <>
      <Helmet>
        <title>Manual Estimate - Lazy Lawn</title>
      </Helmet>
      <div className='font-Monteserrat bg-white'>
        <div
          className='w-full justify-around flex md:items-center sticky top-0 bg-white z-40'
          style={{
            padding: isMobile ? '10px 20px' : '16px 150px',
            borderBottom: '1px solid #eee',
            flexDirection: isMobile ? 'column' : 'row',
          }}
        >
          <div className='flex justify-center items-end'>
            <img
              src={`${process.env.PUBLIC_URL}/assets/logos/lazylawn-logo.png`}
              alt='Lazy Lawn®'
              className='w-56 md:w-40'
            />
            <div className='font-bold text-lazyGreen font-lawn leading-none text-3xl hidden md:block -mb-1'>
              Manual Estimates
            </div>
          </div>
          <div className='flex justify-around md:justify-around flex-1 items-end mt-3 md:pt-0'>
            <div className='font-bold text-lazyGreen font-lawn leading-none text-3xl'>
              <strong className='md:hidden'>Manual Estimates</strong>
            </div>
            <div className=''>
              <Button variant='secondary' customClass='flex items-center'>
                Login
              </Button>
            </div>
          </div>
        </div>
        <div className='p-5 lazy-estimate-inner-container mx-auto mt-3'>
          <div className='text-center p-4 text-textGreen font-Monteserrat font-bold'>
            <p>Please fill the form below to generate a manual estimate</p>
          </div>
          <div className='my-1 p-3 border border-solid border-[#eee] rounded-md w-full shadow-sm relative'>
            {loading && (
              <div className='absolute top-0 bottom-0 left-0 right-0 rounded-md w-full bg-black/30 z-10 flex justify-center items-center'>
                <PageLoader />
              </div>
            )}
            <Formik
              enableReinitialize
              initialValues={{
                fname: '',
                lname: '',
                email: '',
                phone: '',
                address: '',
                estimateArea: '',
                grass: '',
              }}
              validationSchema={manualEstimateValidationSchema}
              className='needs-validation'
              onSubmit={async (values, { resetForm }) => {
                await handleManualEstimateFormSubmission(values);
                resetForm();
              }}
            >
              {({ values, handleSubmit, setFieldValue }) => (
                <form onSubmit={handleSubmit}>
                  <ManualEstimateForm setFieldValue={setFieldValue} values={values} />

                  <div className='flex justify-center gap-2 my-3 py-4'>
                    <button
                      disabled={loading}
                      type='submit'
                      className='bg-secondary text-white font-bold text-xl w-[10rem] cursor-pointer uppercase py-2 px-5 rounded-full mt-2 font-Monteserrat border-2 border-secondary hover:bg-secondary/80 hover:border-opacity-0 hover:text-white disabled:bg-secondary/80 disabled:border-opacity-0 disabled:cursor-not-allowed'
                    >
                      {loading ? <ButtonLoader /> : 'Submit'}
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
        <FooterSection />
      </div>
    </>
  );
};
