import { LAZYLAWN_APIS, retrieveCalendlyEvent } from 'Adapters';
import { SITE_VISIT_BOOKED_TAG, SITE_VISIT_REQUESTED_TAG } from 'Constant';
import useAnalyticsEventTracker from 'hooks/useAnalyticsEventTracker';
import React, { useEffect } from 'react';
import { useCalendlyEventListener, InlineWidget } from 'react-calendly';
import { useLocation, useNavigate } from 'react-router-dom';

export const Calendly = () => {
  const navigate = useNavigate();
  const pathname = useLocation();
  const keapContact = JSON.parse(localStorage.getItem('keapContact'));
  const gaEventTracker = useAnalyticsEventTracker('Site Visit Booking');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onEventScheduledHandler = async (e) => {
    // remove tag from keap contact to indicating site visit requested
    LAZYLAWN_APIS.removeTagsFromContact(SITE_VISIT_REQUESTED_TAG, keapContact?.id);

    // apply new tag to keap contact to indicate site visit booked
    await LAZYLAWN_APIS.applyTagsToContact(SITE_VISIT_BOOKED_TAG, keapContact?.id);

    // retrieve event date from calendly
    localStorage.setItem('eventUrl', e.data.payload.event?.uri);
    const res = await retrieveCalendlyEvent(e.data.payload.event?.uri);

    // update keap contact LeadStatus to "SITE VISIT BOOKED" & SITEVISITDATE
    LAZYLAWN_APIS.updateKeapCustomFields(keapContact?.id, [
      {
        id: 3, // Lead Status => LeadStatus
        content: 'SITE VISIT BOOKED',
      },
      {
        id: 64, // SITE VISIT DATE => SITEVISITDATE
        content: res?.start_time, // update with date from calendly
      },
    ]);

    // remove keap contact from local storage
    localStorage.removeItem('keapContact');
    localStorage.removeItem('calendlyLink');
    navigate('/thank-you');
  };

  const clientCalendly = pathname?.search ? pathname?.search.split('=')[1] : 'toronto-site-visit';

  useCalendlyEventListener({
    onEventScheduled: (e) => {
      onEventScheduledHandler(e);
      gaEventTracker(clientCalendly, 'Site visit booked');
    },
  });

  return (
    <InlineWidget
      url={`https://calendly.com/${clientCalendly}?hide_gdpr_banner=1&primary_color=ff4f00`}
      styles={{ minWidth: '320px', height: '100vh', overflow: 'hidden' }}
      prefill={{
        email: keapContact?.email && keapContact?.email,
        firstName: keapContact?.given_name && keapContact?.given_name,
        lastName: keapContact?.family_name && keapContact?.family_name,
        name:
          keapContact?.given_name &&
          keapContact?.family_name &&
          `${keapContact?.given_name} ${keapContact?.family_name}`,
      }}
    />
  );
};
