export const isLocalhost = process.env.NODE_ENV === 'development';

export const baseUrl_bitly = 'https://api-ssl.bitly.com'; // Bitly URL

export const baseUrl = isLocalhost
  ? 'http://127.0.0.1:8000/api/v1' // DEVELOPMENT ENVIRONMENT
  : 'https://apis.lazylawn.ca/api/v1'; // PRODUCTION ENVIRONMENT

export const calendlyAccessToken =
  'eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjc3MzA5Njg3LCJqdGkiOiI5ZDA3NmVmOS04NGU0LTRmZjUtODEwYi04NTI5OWViYTEyZWYiLCJ1c2VyX3V1aWQiOiI0MGI2YzA0My00YmI2LTQ3NzUtYmQ4NS05MjEyZmNiYTRmZDIifQ.5plgRfm4ePmYbH01CFcmwsEGv9tQ6g2oFV5ZtgonLqcWdVmyxrI2kk_9KrZbnHfxquu-ldlRxxrmQ0JpVdF3lw';

export const estimateSummaryUrl = 'https://my.lazylawn.ca/estimate';

export const FRESHBOOKS_ACCOUNTS = {
  MAIN: {
    ACCOUNT_ID: 'BYz3l',
    ACCOUNT_EMAIL: 'info@lazylawn.ca',
    UNIQUE_ID: 'BYz',
  },
  SECONDARY: {
    ACCOUNT_ID: '02OVwd',
    ACCOUNT_EMAIL: 'estimate@lazylawn.ca',
    UNIQUE_ID: 'Vwd',
  },
};

export const LAZYLAWN_DEFAULT_COUNTRY = 'Canada';

export const SITE_VISIT_REQUESTED_TAG = 268;
export const PROJECT_BOOKED_TAG = 144;
export const CAN_SITE_VISIT_REQUESTED_TAG = 1132;
export const SITE_VISIT_BOOKED_TAG = 502;

export const TRACKING_ID = 'UA-43394607-4'; // Google Analytics Tracking ID

export const isManualEstimate = window.location.pathname === '/manual-estimate';

export const INSTALLATION_OPTIONS = {
  FULL: 'full', // Full Installation
  PUTTING_GREEN: 'putting', // Putting Green Installation
  DIY: 'diy', // DIY Installation
  BALCONY: 'balcony', // Balcony/Terrace Installation
  PLAYGROUND: 'playground', // Playground Installation
};

export const GRASS_TYPES_OPTIONS = {
  SPRING_FESCUE: 'Spring Fescue',
  NATURAL_FESCUE: 'Natural Fescue',
  LEMONGRASS: 'Lemongrass',
  PUTTING_GREEN: 'Birdie Pro Putt',
  KENTUCKY_BLUE_LITE: 'Kentucky Blue Lite',
};

export const grassTypes = [
  {
    name: GRASS_TYPES_OPTIONS.SPRING_FESCUE,
    image: '40743c69-f78b-4474-a268-13a625e2ed00',
    price: 4.29,
    faceHeight: '1.5',
    faceWeight: '50 Oz',
  },
  {
    name: GRASS_TYPES_OPTIONS.NATURAL_FESCUE,
    image: '4e0e4e3e-e0ea-49bb-f777-cb677a6ae300',
    price: 5.69,
    faceHeight: '1.75',
    faceWeight: '75 Oz',
  },
  {
    name: GRASS_TYPES_OPTIONS.LEMONGRASS,
    image: '2e8b4b67-cd4b-400c-ef28-8f8800b13600',
    price: 5.79,
    faceHeight: '1.5',
    faceWeight: '70 Oz',
  },
  {
    name: GRASS_TYPES_OPTIONS.KENTUCKY_BLUE_LITE,
    image: '5db7a32a-e177-41d5-1bdd-f55e2b6cf100',
    price: 5.89,
    faceHeight: '1.38',
    faceWeight: '80 Oz',
  },
  {
    name: GRASS_TYPES_OPTIONS.PUTTING_GREEN,
    image: '53287ae3-a802-4a44-f128-6027350b8e00',
    price: 5.99,
    faceHeight: '3/8',
    faceWeight: '55 Oz',
  },
];

export const installationTypes = [
  {
    name: 'Full Installation',
    image: `fd8b2830-ecdb-4a60-cb3b-85c233418d00`,
    value: INSTALLATION_OPTIONS.FULL,
    description: 'Tired of constantly mowing, watering and dealing with weeds?',
  },
  {
    name: 'Putting Green Installation',
    image: `085fa8f9-1f68-4675-810c-80957382f300`,
    value: INSTALLATION_OPTIONS.PUTTING_GREEN,
    description: 'Looking to shave a few strokes off your game?',
  },
  {
    name: 'Do-It-Yourself',
    image: `1420fbf1-dafd-49ef-0ed5-cd8bdcf6c900`,
    value: INSTALLATION_OPTIONS.DIY,
    description: "Looking to save some money, we'll guide you every step on of the way.",
  },
  {
    name: 'Balcony/Terrace Installation',
    image: `686ecc2a-2a08-47d8-870c-58ed062ebe00`,
    value: INSTALLATION_OPTIONS.BALCONY,
    description: 'Tired of concrete? Spruce up your balcony with Lazy Lawn.',
  },
  {
    name: 'Playgrounds',
    image: `67636568-ef7b-49ae-f2c3-f7a4498f5d00`,
    value: INSTALLATION_OPTIONS.PLAYGROUND,
    description: '',
  },
];

export const playgroundTypes = [
  {
    name: 'Grass',
    image: `67636568-ef7b-49ae-f2c3-f7a4498f5d00`,
    value: 'grass_based',
  },
  {
    name: 'Sand',
    image: `65885a4a-ec28-423d-9733-4466acea1d00`,
    value: 'sand_based',
  },
  {
    name: 'Concrete/asphalt',
    image: `30399412-7007-44a6-9bc4-3e5cf4e04d00`,
    value: 'concrete_based',
  },
];

export const grassOptions = grassTypes.map((grass) => ({
  value: grass.name,
  label: grass.name,
}));

export const installationOptions = installationTypes.map((option) => ({
  value: option.value,
  label: option.name,
}));

export const ROUGH_ESTIMATE_ITEMS = {
  DIY_ESTIMATE: {
    description: 'DIY GUESSIMATE ONLY.  SITE VISIT REQUIRED.',
    name: 'DIY GUESSIMATE (CLIENT GENERATED ROUGH ESTIMATE)',
  },
  ROUGH_ESTIMATE_ONLY: {
    description: 'ROUGH ESTIMATE ONLY.  SITE VISIT REQUIRED.',
    name: 'GUESSTIMATE (ROUGH ESTIMATE)',
  },
  ROUGH_ESTIMATE: {
    description: 'ROUGH ESTIMATE',
    name: 'ROUGH ESTIMATE',
  },
};
