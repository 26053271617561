import axiosMain from 'axios';
import { calendlyAccessToken } from 'Constant';
import generalAdapter from './general';
import { generalAxios } from './Interceptors/general-interceptor';

export const retrieveCalendlyEvent = async (eventUrl) => {
  const res = await axiosMain(eventUrl, {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${calendlyAccessToken}`,
    },
  });

  return res?.data?.resource;
};

/**
 * @name dashboardRequestor
 * @param {RequestConfig} config - Request settings.
 * @description - This function is used to set the axios interceptor.
 * @returns {void}
 * */
export const generalRequestor = async (config) => {
  try {
    const { url, data } = config;
    const res = await generalAxios(url, {
      method: 'GET',
      credentials: 'include',
      body: data ? JSON.stringify(data) : null,
      ...config,
    });

    return res;
  } catch (error) {
    return error;
  }
};

const LAZYLAWN_APIS = generalAdapter(generalRequestor);

export { LAZYLAWN_APIS };
