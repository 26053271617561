import { toast } from 'react-toastify';

/**
 * @name Alert
 * @param {"success" | "error" | "info" | "warning"} variant
 * @param {string} message
 * @param {Object} options
 */
const Alert = (variant, message, options) => toast[variant](message, options);

export default Alert;
