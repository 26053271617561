import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import AppRoutes from './Routes';

const App = () => (
  <Router>
    <AppRoutes />
    <ToastContainer />
  </Router>
);

export default App;
